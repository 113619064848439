<template>
  <div>
    <div class="text-justify">
      {{ displayText }}
    </div>
    <span
      class="text-primary"
      @click="toggleReadMore"
    >
      {{ showLess ? 'Lihat Selengkapnya' : 'Baca lebih sedikit' }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    length: {
      type: Number,
      default: 75,
    },
  },
  data() {
    return {
      showLess: true,
    }
  },
  computed: {
    displayText() {
      return this.showLess ? `${this.text.slice(0, this.length)}...` : this.text
    },
  },
  methods: {
    toggleReadMore() {
      this.showLess = !this.showLess
    },
  },
}
</script>
