<template>
  <BCard>
    <div class="flex flex-row items-center gap-7">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        alt="Komerce"
        class="cursor-pointer"
        @click="$router.back()"
      >
      <div class="text-black font-medium text-2xl">
        Riwayat Pengajuan
      </div>
    </div>
    <BRow class="my-2 justify-between items-center">
      <BCol
        lg="6"
        md="6"
        sm="12"
        class="mb-1"
      >
        <div class="flex items-center justify-between">
          <BImg src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user.svg" />
          <label class="text-black text-lg mx-1">Nama</label>
          <BFormInput
            v-model="partnerName"
            disabled
          />
        </div>
      </BCol>
      <BCol
        lg="6"
        md="6"
        sm="12"
        class="mb-1"
      >
        <div class="flex items-center justify-between">
          <BImg src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/sms.svg" />
          <label class="text-black text-lg mx-1">Email</label>
          <BFormInput
            v-model="partnerEmail"
            disabled
          />
        </div>
      </BCol>
    </BRow>
    <div class="text-black font-medium text-2xl">
      Data Pengajuan
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div style="height: calc(100vh - 330px); overflow-y: scroll;">
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
          hover
          selectable
          select-mode="single"
        >
          <template #cell(requested_date)="data">
            <span class="font-medium">{{ DAY_MONTH_YEAR(data.item.requested_date) }}</span>
            <br>
            <span class="text-sm text-[#828282]">
              {{ TIME(data.item.requested_date) }}
            </span>
          </template>
          <template #cell(ktp_face_picture_notes)="data">
            <div v-if="data.item.ktp_face_picture_notes !== null">
              <div v-if="data.item.ktp_face_picture_notes.length > 100">
                <ReadMore :text="data.item.ktp_face_picture_notes" />
              </div>
              <div v-else>
                {{ data.item.ktp_face_picture_notes }}
              </div>
            </div>
            <span v-else>Tidak Ada</span>
          </template>
          <template #cell(ktp_picture_notes)="data">
            <div v-if="data.item.ktp_picture_notes !== null">
              <div v-if="data.item.ktp_picture_notes.length > 100">
                <ReadMore :text="data.item.ktp_picture_notes" />
              </div>
              <div v-else>
                {{ data.item.ktp_picture_notes }}
              </div>
            </div>
            <span v-else>Tidak Ada</span>
          </template>
          <template #cell(file)="data">
            <span
              class="text-primary underline"
              @click="$router.push(`/verification-ktp/history/${data.item.verif_ktp_id}/${data.item.id}/${partnerId}`)"
            >
              Lihat Berkas
            </span>
          </template>
          <template #cell(status)="data">
            <div :class="getClassStatus(data.item.status)">
              {{ getLabelStatus(data.item.status) }}
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import ReadMore from '@/views/components/ReadMore.vue'
import { alertError } from '@toast'
import {
  configColumnHistory, getLabelStatus, getClassStatus,
} from './config'

export default {
  components: { ReadMore },
  data() {
    return {
      items: [],
      fields: configColumnHistory,
      loading: false,
      DAY_MONTH_YEAR,
      TIME,
      getLabelStatus,
      getClassStatus,
      idVerif: this.$route.params.id,
      partnerName: '',
      partnerEmail: '',
      alertError,
      partnerId: null,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      const url = `/auth/api/v1/admin/ktp-verification/${this.idVerif}/history`
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data, meta: { partner_name, partner_email, partner_id } } = res.data
          // eslint-disable-next-line camelcase
          this.partnerName = partner_name
          // eslint-disable-next-line camelcase
          this.partnerEmail = partner_email
          this.loading = false
          this.items = data
          // eslint-disable-next-line camelcase
          this.partnerId = partner_id
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal load data' })
        })
    },
    toggleCollapse(index) {
      this.$set(
        this.collapseText,
        index,
        this.collapseText[index] === 'Lihat Selengkapnya'
          ? 'Sembunyikan'
          : 'Lihat Selengkapnya',
      )
    },
  },
}
</script>
